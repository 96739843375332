import React, { useState } from 'react';

export default function TaskColumn({ column, onEditTitle, onDeleteColumn, children }) {
    const [editing, setEditing] = useState(false);
    const [newTitle, setNewTitle] = useState(column);

    const handleSaveTitle = () => {
        onEditTitle(newTitle);
        setEditing(false);
    };

    return (
        <div className="task-column">
            <div className="task-column__header">
                {editing ? (
                    <>
                        <input
                            type="text"
                            value={newTitle}
                            onChange={(e) => setNewTitle(e.target.value)}
                            className="task-column__title-input"
                        />
                        <div>
                            <button className="btn me-1" onClick={handleSaveTitle}>Save</button>
                            <button className="btn" onClick={() => setEditing(false)}>Cancel</button>
                        </div>
                    </>
                ) : (
                    <>
                        <h3>{column}</h3>
                        <div>
                            <button className="btn me-1" onClick={() => setEditing(true)}>Edit</button>
                            <button className="btn" onClick={onDeleteColumn}>Delete</button>
                        </div>
                    </>
                )}
            </div>
            {children}
        </div>
    );
}
