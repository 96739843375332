import React, { useState } from "react";
import Select from "react-select";

// Stil tanımlamaları
const styles = {
  formGroup: {
    marginBottom: "15px",
  },
  inlineContainer: {
    display: "flex",
    justifyContent: "space-between",
    gap: "15px",
  },
  label: {
    display: "block",
    marginBottom: "3px",
    fontWeight: "bold",
    color: "#333",
    fontSize: "14px",
  },
  // Tüm input'lar için ortak stil
  input: {
    width: "100%",
    padding: "8px",
    border: "1px solid #ddd",
    borderRadius: "4px",
    fontSize: "13px",
    transition: "border-color 0.3s",
    height: "36px", // Title input ile aynı yükseklik
  },
  inputFocus: {
    outline: "none",
    borderColor: "#007bff",
    boxShadow: "0 0 0 2px rgba(0,123,255,.25)",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "20px",
  },
  button: {
    padding: "8px 15px",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    fontSize: "14px",
    transition: "background-color 0.3s",
    backgroundColor: "#007bff",
    color: "white",
  },
  buttonHover: {
    filter: "brightness(90%)",
  },
};

export default function AddTaskForm({ currentBoard, onSubmit }) {
  const [task, setTask] = useState({
    title: "",
    description: "",
    startDate: "",
    endDate: "",
    appointedUserId: null,
    state: "",
  });

  const [focusedInput, setFocusedInput] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(task);
  };

  const userOptions = currentBoard.users
    ? currentBoard.users.map((user) => ({
        value: user.userId,
        label: (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={user.profileImg}
              alt={user.fullName}
              style={{
                width: "25px",
                height: "25px",
                marginRight: "10px",
                borderRadius: "50%",
              }}
            />
            {user.fullName}
          </div>
        ),
      }))
    : [];

  const stateOptions = currentBoard.states.map((state) => ({
    value: state,
    label: state,
  }));

  const handleUserChange = (selectedOption) => {
    if (selectedOption) {
      setTask((prevTask) => ({
        ...prevTask,
        appointedUserId: selectedOption.value,
      }));
    }
  };

  const handleStateChange = (selectedOption) => {
    if (selectedOption) {
      setTask((prevTask) => ({
        ...prevTask,
        state: selectedOption.value,
      }));
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTask((prevTask) => ({ ...prevTask, [name]: value }));
  };

  // Select bileşenleri için özel stiller
  const selectStyles = {
    control: (provided) => ({
      ...provided,
      ...styles.input,
      padding: "0",
      minHeight: "36px",
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "0 8px",
    }),
    input: (provided) => ({
      ...provided,
      margin: "0",
      padding: "0",
    }),
  };

  return (
    <form onSubmit={handleSubmit}>
      <div style={styles.formGroup}>
        <label htmlFor="editTaskTitle" style={styles.label}>
          Task Title
        </label>
        <input
          type="text"
          id="editTaskTitle"
          name="title"
          value={task.title}
          onChange={handleInputChange}
          required
          style={{
            ...styles.input,
            ...(focusedInput === "title" ? styles.inputFocus : {}),
          }}
          onFocus={() => setFocusedInput("title")}
          onBlur={() => setFocusedInput(null)}
        />
      </div>

      <div style={styles.formGroup}>
        <label htmlFor="editTaskDescription" style={styles.label}>
          Task Description
        </label>
        <textarea
          id="editTaskDescription"
          name="description"
          value={task.description}
          onChange={handleInputChange}
          style={{
            ...styles.input,
            ...(focusedInput === "description" ? styles.inputFocus : {}),
            minHeight: "80px",
            height: "auto",
          }}
          onFocus={() => setFocusedInput("description")}
          onBlur={() => setFocusedInput(null)}
        />
      </div>

      <div style={styles.inlineContainer}>
        <div style={{ ...styles.formGroup, flex: 1 }}>
          <label htmlFor="editTaskStartDate" style={styles.label}>
            Start Date
          </label>
          <input
            type="date"
            id="editTaskStartDate"
            name="startDate"
            value={task.startDate}
            onChange={handleInputChange}
            style={{
              ...styles.input,
              ...(focusedInput === "startDate" ? styles.inputFocus : {}),
            }}
            onFocus={() => setFocusedInput("startDate")}
            onBlur={() => setFocusedInput(null)}
          />
        </div>
        <div style={{ ...styles.formGroup, flex: 1 }}>
          <label htmlFor="editTaskEndDate" style={styles.label}>
            End Date
          </label>
          <input
            type="date"
            id="editTaskEndDate"
            name="endDate"
            value={task.endDate}
            onChange={handleInputChange}
            style={{
              ...styles.input,
              ...(focusedInput === "endDate" ? styles.inputFocus : {}),
            }}
            onFocus={() => setFocusedInput("endDate")}
            onBlur={() => setFocusedInput(null)}
          />
        </div>
      </div>

      <div style={styles.formGroup}>
        <label htmlFor="assignedUser" style={styles.label}>
          Assigned User
        </label>
        <Select
          name="appointedUserId"
          value={userOptions.find(
            (option) => option.value === task.appointedUserId
          )}
          onChange={handleUserChange}
          options={userOptions}
          styles={selectStyles}
          onFocus={() => setFocusedInput("assignedUser")}
          onBlur={() => setFocusedInput(null)}
        />
      </div>

      <div style={styles.formGroup}>
        <label htmlFor="taskState" style={styles.label}>
          Status
        </label>
        <Select
          name="state"
          value={stateOptions.find((option) => option.value === task.state)}
          onChange={handleStateChange}
          options={stateOptions}
          styles={selectStyles}
          onFocus={() => setFocusedInput("taskState")}
          onBlur={() => setFocusedInput(null)}
        />
      </div>

      <div style={styles.buttonContainer}>
        <button
          type="submit"
          style={styles.button}
          onMouseOver={(e) =>
            (e.target.style.filter = styles.buttonHover.filter)
          }
          onMouseOut={(e) => (e.target.style.filter = "none")}
        >
          Create Task
        </button>
      </div>
    </form>
  );
}
