import React, { useState, useEffect } from "react";
import "./ColumnScrollIndicator.scss";

const ColumnScrollIndicator = ({ totalDots = 10 }) => {
  const [activeDots, setActiveDots] = useState(0);

  useEffect(() => {
    const container = document.querySelector(".columns-container");
    if (!container) return;

    const handleScroll = () => {
      const scrollableWidth = container.scrollWidth - container.clientWidth;
      const currentScroll = container.scrollLeft;
      const scrollPercentage = currentScroll / scrollableWidth;
      const newActiveDots = Math.round(scrollPercentage * totalDots);
      setActiveDots(newActiveDots);
    };

    container.addEventListener("scroll", handleScroll);
    handleScroll(); // İlk yüklemede scroll durumunu kontrol et

    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [totalDots]);

  return (
    <div className="column-scroll-indicator">
      {[...Array(totalDots)].map((_, index) => (
        <span
          key={index}
          className={`dot ${index < activeDots ? "active" : ""}`}
        />
      ))}
    </div>
  );
};
export default ColumnScrollIndicator;
