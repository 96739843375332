import axios from 'axios';
import Cookies from 'js-cookie';
import jwt from 'jwt-decode';
import React, { useState } from 'react';
import ReactLoading from 'react-loading';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import UpperModal from '../../components/UpperModal';
import { LOGIN, RESET_PASSWORD } from '../../constants/links';

function Login() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [inputShow, setInputShow] = useState(false);
    const [info, setInfo] = useState({
        'email': '',
        'password': ''
    });
    const [resetEmail, setResetEmail] = useState("");

    const [resetEmailLoading, setResetEmailLoading] = useState(false)

    const dispatch = useDispatch();

    const handleTextInputs = (value, name) => {
        let updatedValue = { [name]: value };
        setInfo(info => ({
            ...info,
            ...updatedValue
        }));
    };

    const login = () => {
        if (info.email !== '' && info.password !== '') {
            setLoading(true);
            axios.post(LOGIN, { ...info })
                .then(res => {
                    Cookies.set("jwt_user", res.data.token, {
                        expires: new Date(jwt(res.data.token).exp * 1000)
                    });
                    setLoading(false);
                    navigate('/dashboard');
                })
                .catch(err => {
                    toast.error(err.response.data.message, { theme: "colored", position: "bottom-right" });
                    setLoading(false);
                });
        } else {
            toast.error('Please fill all the fields', { theme: "colored", position: "bottom-right" });
        }
    };

    const handleResetPassword = () => {
        if (resetEmail !== "") {
            setResetEmailLoading(true)
            axios.post(RESET_PASSWORD, { email: resetEmail })
                .then(res => {
                    Cookies.set("reset_password", res.data.token, {
                        expires: new Date(jwt(res.data.token).exp * 1000),
                        secure: true, // Sadece HTTPS üzerinden kullanılabilir
                        sameSite: 'Strict',
                    });
                    setResetEmail("")
                    toast.success('Password reset link sent successfully', { theme: 'colored', position: 'bottom-right' });

                })
                .catch(err => {
                    toast.error("Something Went Wrong", { theme: "colored", position: "bottom-right" });
                    setLoading(false);
                })
                .finally(() => setResetEmailLoading(false))
            // Şifre sıfırlama isteği için işlem ekleyebilirsiniz
        } else {
            toast.error('Please enter your email address', { theme: "colored", position: "bottom-right" });
        }
    };

    return (
        <div className="login-main" tabIndex={0} onKeyDown={(e) => e.key === 'Enter' ? login() : null}>
            <div className='login-form'>
                <h5>Welcome to UpperBoard</h5>
                <p>Enter your email and password to sign in</p>
                <div className="mt-4">
                    <div>
                        <div className="mb-3">
                            <label>Email</label>
                            <input
                                maxLength={40}
                                onChange={(e) => handleTextInputs(e.target.value, 'email')}
                                placeholder='Your email address'
                                className="form-control"
                            />
                        </div>

                        <div className="mb-3">
                            <label>Password</label>
                            <div className='login-form-password-div'>
                                <input
                                    type={inputShow ? "text" : "password"}
                                    maxLength={40}
                                    className="form-control"
                                    onChange={(e) => handleTextInputs(e.target.value, 'password')}
                                    placeholder='Password'
                                />
                                <button
                                    style={{ height: 38 }}
                                    onClick={() => setInputShow(!inputShow)}
                                    className="btn btn-dark"
                                    type="button"
                                    id="password-addon"
                                >
                                    <i className="mdi mdi-eye-outline"></i>
                                </button>
                            </div>
                        </div>
                        <div>
                            <input type="checkbox" />
                            <label style={{ paddingLeft: 10 }}>
                                Remember me
                            </label>
                        </div>
                        <ToastContainer />
                        <div className="mt-3 d-grid">
                            <button onClick={login} className="btn btn-dark btn-block d-flex justify-content-center align-items-center">
                                {loading ? (
                                    <ReactLoading type={'spinningBubbles'} color={'white'} height={20} width={20} />
                                ) : 'Log In'}
                            </button>
                        </div>
                    </div>
                    <div className="mt-5 text-center">
                        <p>Don't have an account?
                            <Link to={'/apply'} style={{ textDecoration: 'none' }}>
                                <span> Sign Up</span>
                            </Link>
                        </p>
                        <p>Forgot your password?
                            <span
                                style={{ cursor: 'pointer', color: 'black' }}
                                data-toggle="modal"
                                data-target="#resetPasswordModal"
                            > Reset Password
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            <img src='/assets/images/signUpBanner.png' className='login-img' />

            {/* Reset Password UpperModal */}
            <UpperModal
                modalId="resetPasswordModal"
                type="md"
                width={500}
                contentHTML={
                    <div>
                        <h5 className="mb-3">Send Reset Password Link</h5>
                        <div className="form-group">
                            <label>Email</label>
                            <input
                                type="email"
                                className="form-control"
                                value={resetEmail}
                                onChange={(e) => setResetEmail(e.target.value)}
                                placeholder="Enter your email address"
                            />
                        </div>
                        <button
                            className="btn btn-dark mt-3"
                            onClick={handleResetPassword}
                            disabled={resetEmailLoading}
                        >
                            {resetEmailLoading ? (
                                <ReactLoading type={'spinningBubbles'} color={'white'} height={20} width={20} />
                            ) : 'Send'}
                        </button>
                    </div>
                }
            />
        </div>
    );
}

export default Login;
