import axios from 'axios';
import jwtDecode from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { CHANGE_PASSWORD, RESET_PASSWORD_CHECK_TOKEN } from '../../constants/links';
import { getResetPasswordToken } from '../../helpers/cookieHelper';

function ResetPassword() {
    const location = useLocation();
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');

    const navigate = useNavigate()

    useEffect(() => {
        const path = location.pathname.split("/");

        if (path[path.length - 1] === jwtDecode(getResetPasswordToken()).resetPasswordId) {

            axios.post(RESET_PASSWORD_CHECK_TOKEN, { token: getResetPasswordToken() })
                .then((res) => {
                    if (!res.data.tokenCheck) {
                        navigate("/login")
                    }
                })
                .catch((err) => {
                    console.log(err)
                })

        }
        else {
            navigate("/login")
        }

    }, [location]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            if (confirmPassword.length < 6) {
                setError('Password have to be 6 characters');
            }
            else {

                setError('Passwords do not match');
            }
        } else {
            setError('');

            axios.post(CHANGE_PASSWORD, { token: getResetPasswordToken(), password: newPassword })
                .then((res) => {
                    toast.success('Password Changed Successfully!', { theme: 'colored', position: 'bottom-right' });
                    setConfirmPassword("")
                    setNewPassword("")
                    setTimeout(() => {
                        navigate("/login")
                    }, 2000);
                })
                .catch((err) => {
                    console.log(err)
                    toast.error('Something Went Wrong!', { theme: 'colored', position: 'bottom-right' });

                })
        }
    };

    return (
        <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
            <ToastContainer />
            <Row className="w-100">
                <Col md={{ span: 6, offset: 3 }}>
                    <div className="p-4 shadow rounded bg-white">
                        <h2 className="text-center mb-4">Reset Your Password</h2>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="newPassword" className="mb-3">
                                <Form.Label>New Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="Enter new password"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="confirmPassword" className="mb-3">
                                <Form.Label>Confirm New Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="Re-enter new password"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    required
                                />
                            </Form.Group>
                            {error && <div className="text-danger mb-3">{error}</div>}
                            <Button variant="dark" type="submit" className="w-100">
                                Reset Password
                            </Button>
                        </Form>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default ResetPassword;
