import React from 'react';

const styles = {
    title: {
        fontWeight: 'bold',
        fontSize: '16px',
        marginBottom: '5px',
    },
    description: {
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        marginBottom: '10px',
        color: '#6c757d', // gri renk
        fontSize: '14px',
    },
    taskActions: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    userInfo: {
        display: "flex",
        alignItems: "center",
    },
    userImage: {
        width: "25px",
        height: "25px",
        marginRight: "10px",
        borderRadius: "50%",
    },
};

export default function Task({ currentBoard, task, provided, onEdit, onDelete }) {
    const appointedUser = currentBoard.users && currentBoard.users.find((user) => user.userId === task.appointedUserId);

    return (
        <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className="task"
        >
            <div className="task-content" style={styles.title}>{task.title}</div>
            <div className="task-content" style={styles.description}>
                {task.description}
            </div>
            <div className="task-actions" style={styles.taskActions}>
                <div>
                    {appointedUser && (
                        <div style={styles.userInfo}>
                            <img
                                src={appointedUser.profileImg}
                                alt={appointedUser.fullName}
                                style={styles.userImage}
                            />
                            <span>{appointedUser.fullName}</span>
                        </div>
                    )}
                </div>

                <div>

                    <button className="btn" onClick={onEdit}>View</button>
                    <button className="btn" onClick={() => onDelete(task._id)}>Delete</button>
                </div>
            </div>
        </div>
    )
}